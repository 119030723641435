import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Carousel } from '../../carousel/component';
import { DoorView } from '../../../../utilities/components/door/component/view';

import './view.scss';

export const BestsellersView = ({ bestsellers, types }) => {
    const [type, setType] = useState('');
    const filterItems = () => (bestsellers.filter((currentValue) => (currentValue.type === type)));

    return (
        <Carousel
            headline="Nasze bestsellery"
            buttonText="Zobacz więcej"
            // /produkty is modifier for newly generated link for the button below carousel. It is used in <Carousel> from /features/index/carousel
            buttonLink="/produkty"
            name="bestsellers"
            modifier="bestsellers__item"
            types={ types }
            loopBreakpoint={ 7 }
            slidesPerView="auto"
            type={ type }
            items={ (type)
                ? (filterItems())
                : (bestsellers) }
            setType={ setType }
        >
            <DoorView inSwiper isBestseller />
        </Carousel>
    );
};

BestsellersView.propTypes = {
    bestsellers: PropTypes.instanceOf(Array).isRequired,
    types: PropTypes.instanceOf(Array).isRequired
};