import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { ArticlesView } from './view';

export const Articles = () => {
    const data = useStaticQuery(graphql`
        query {
            allArticles(filter: { is_active: { eq: 1 } }) {
                nodes {
                    alternative_id
                    author
                    created_at(formatString: "DD.MM.YYYY")
                    image_main
                    lead
                    title
                    type
                    slug
                    reading_time
                }
            }
        }
    `);
    const types = Array.from(new Set(data?.allArticles?.nodes?.map((currentValue) => currentValue.type)));
    const sortedTypes = types.sort((a) => (a === 'Design' ? -1 : 1));

    return <ArticlesView articles={ data?.allArticles?.nodes } types={ sortedTypes } />;
};
