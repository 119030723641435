import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { LeadTimesView } from './view';

export const LeadTimes = () => {
    const data = useStaticQuery(graphql`
    query allRealizationsTimes {
      allRealizationsTimes {
        nodes {
          settings {
            bullets
            time
          }
        }
      }
    }
    `);

    return <LeadTimesView deadlines={ data?.allRealizationsTimes?.nodes?.length && data?.allRealizationsTimes?.nodes[0]?.settings } />;
};