import React from 'react';
import { Link } from 'gatsby';

import { HeadlineView } from 'utilities/components/headline/component/view';
import { Img } from 'utilities/components/img/component';

import trapezoid from 'assets/images/index/flag.svg';
import baranskiPremium from 'assets/images/baranski-premium.svg';
import baranskiOptimo from 'assets/images/baranski-optimo.svg';
import baranskiSmart from 'assets/images/baranski-smart.svg';
import { paths } from 'utilities/routes';

import './view.scss';

export const BrandsView = () => (
    <section className="brands">
        <HeadlineView text="Nasze marki" />
        <div className="brands__container">
            <Link to={ paths.premium } className="brands__brand">
                <div className="brand__overlay" />
                <Img className="brand__image" src={ trapezoid } alt="trapez" />
                <Img className="brand__logo" src={ baranskiPremium } alt="barański premium" />
                <h4 className="brand__headline">Drzwi wewnętrzne i&nbsp;zewnętrzne&nbsp;drewniane</h4>
                <p className="brand__description">Pierwszorzędne materiały, najwyższa&nbsp;jakość</p>
            </Link>
            <Link to={ paths.optimo } className="brands__brand">
                <div className="brand__overlay" />
                <Img className="brand__image" src={ trapezoid } alt="trapez" />
                <Img className="brand__logo" src={ baranskiOptimo } alt="barański optimo" />
                <h4 className="brand__headline">Drzwi wewnętrzne okleinowane</h4>
                <p className="brand__description">Optymalne połączenie estetyki i funkcjonalności</p>
            </Link>
            <Link to={ paths.smart } className="brands__brand">
                <div className="brand__overlay" />
                <Img className="brand__image" src={ trapezoid } alt="trapez" />
                <Img className="brand__logo" src={ baranskiSmart } alt="barański smart" />
                <h4 className="brand__headline">Drzwi klatkowe i&nbsp;techniczne</h4>
                <p className="brand__description">Wyjątkowe drzwi do zadań specjalnych</p>
            </Link>
        </div>
    </section>
);