import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { parseDoors, doorTypes, shortNameTypes } from 'utilities/data/doors';

import { BestsellersView } from './view';

export const Bestsellers = () => {
    const data = useStaticQuery(graphql`
        query getBestsellersDoors {
            allDoors(filter: { is_active: { eq: 1 }, bestseller: { eq: 1 } }) {
                nodes {
                    alternative_id
                    variants {
                        main_model
                        main
                    }
                    custom_variants {
                        main
                        main_model
                    }
                    name
                    lines {
                        name
                        brands {
                            cms_name
                            name
                            type
                        }
                    }
                    price
                    price_type
                    purposes {
                        name
                    }
                    style
                    bestseller
                }
            }
        }
    `);

    const doors = data?.allDoors?.nodes;

    const doorsFlatStructure = parseDoors(doors);

    const doorsWithCustomType = doorsFlatStructure?.map((door) => {
        const doorType = door.type;
        let newDoorType = doorType;
        if (doorType) {
            if (doorType === doorTypes.inner.name) {
                newDoorType = doorTypes.inner.shortName;
            }
            if (doorType === doorTypes.outer.name) {
                newDoorType = doorTypes.outer.shortName;
            }
            if (doorType === doorTypes.technical.name) {
                newDoorType = doorTypes.technical.shortName;
            }
        }

        const newDoor = { ...door, type: newDoorType };

        return newDoor;
    });

    return <BestsellersView bestsellers={ doorsWithCustomType } types={ shortNameTypes } isBestseller />;
};
