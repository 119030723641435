import React from 'react';
import { Link } from 'gatsby';
import SwiperCore, { Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import PropTypes from 'prop-types';

import trapezoidPremium from '../../../../assets/images/index/trapezoid--premium.svg';
import trapezoidOptimo from '../../../../assets/images/index/trapezoid--optimo.svg';
import trapezoidSmart from '../../../../assets/images/index/trapezoid--smart.svg';
import trapezoid from '../../../../assets/images/index/trapezoid.svg';

import './view.scss';

SwiperCore.use([Autoplay, Pagination]);

export const SliderView = ({ slides }) => (
    <section className="slider">
        <Swiper
            pagination={{
                clickable: true,
                el: '.slider__pagination',
                bulletClass: 'pagination__bullet',
                bulletActiveClass: 'pagination__bullet--active',
                renderBullet: (index, className) => (`<div class="${className}"></div>`)
            }}
            autoplay={{ delay: 6000 }}
            loop
        >
            {
                slides.sort((a, b) => (a.position - b.position)).map((currentValue, index) => (
                    <SwiperSlide
                        className="slider__slide"
                        style={{
                            backgroundImage: `url("${
                                (currentValue.banner_main_color === 'Premium')
                                    ? (trapezoidPremium)
                                    : (currentValue.banner_main_color === 'Optimo')
                                        ? (trapezoidOptimo)
                                        : (currentValue.banner_main_color === 'Smart')
                                            ? (trapezoidSmart)
                                            : (trapezoid)
                            }"), url("${process.env.GATSBY_URL}/${currentValue.image}")`,
                            backgroundPositionX: `left, ${currentValue.background_position_x || 0}%`
                        }}
                        key={ index }
                    >
                        <div className="slide__content">
                            <h2 className="slide__headline">{ currentValue.name.pl }</h2>
                            <p className="slide__description">{ currentValue.banner_title.pl }</p>
                            <Link className="slide__button" to={ currentValue.target_link }>{ currentValue.button_text.pl }</Link>
                        </div>
                    </SwiperSlide>
                ))
            }
        </Swiper>
        <div className="slider__pagination" />
    </section>
);

SliderView.propTypes = {
    slides: PropTypes.instanceOf(Array).isRequired
};