import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { OfferView } from './view';

export const Offer = () => {
    const data = useStaticQuery(graphql`
        query GetOfferImage {
            allFolders(filter: {
                id: {
                    ne: "dummy"
                }
                assortment: {
                    name: {
                        eq: "Premium zewnętrzne"
                    }
                }
            }) {
                nodes {
                    homepage_thumbnail_img
                }
            }
        }
    `);

    return (<OfferView image={ data?.allFolders?.nodes[0]?.homepage_thumbnail_img } />);
};