import React from 'react';
import PropTypes from 'prop-types';

import { ButtonView } from 'utilities/components/button/component/view';
import { Img } from 'utilities/components/img/component';
import { paths } from 'utilities/routes';

import './view.scss';

export const OfferView = ({ image }) => (
    <section className="offer">
        <div className="offer__container">
            <h4 className="offer__headline">Dowiedz się więcej o naszej ofercie</h4>
            <p className="offer__description"> </p>
            <ButtonView text="Zobacz katalog" link={ paths.katalogi } />
            <Img className="offer__image" src={ `${process.env.GATSBY_URL}/${image}` } alt="katalog" />
        </div>
    </section>
);

OfferView.propTypes = {
    image: PropTypes.string.isRequired
};