import React from 'react';

import { Navigation } from '../utilities/components/navigation/component';
import { Slider } from '../features/index/slider/component';
import { CompanyView } from '../features/index/company/component/view';
import { Bestsellers } from '../features/index/bestsellers/component';
import { Offer } from '../features/index/offer/component';
import { BrandsView } from '../features/index/brands/component/view';
import { LeadTimes } from 'features/index/lead-times/component';
import { Showrooms } from '../features/index/showrooms/component';
import { Articles } from '../features/index/articles/component';
import { Implementations } from 'features/index/implementations/component';
import { SeoDecription } from 'features/index/seo-description/view';
import { Footer } from '../utilities/components/footer/component';
import { Breadcrumbs } from 'utilities/components/breadcrumbs/view';
import { routes } from 'utilities/routes';
import { MESSENGER_APP_ID, MESSENGER_PAGE_ID } from 'utilities/consts';
import MessengerCustomerChat from 'react-messenger-customer-chat';

import '../assets/styles/vendors.scss';
import '../assets/styles/normalize.scss';
import '../assets/styles/fonts.scss';

import LogRocket from 'logrocket';
import SEO from 'utilities/components/seo';
import { Helmet } from 'react-helmet';
import { useSeoTexts } from 'utilities/hooks/useSeoTexts';

LogRocket.init('qijaj0/baranski');

const Index = () => {
    const breadcrumbs = [routes.home];
    const { metaTitle, metaDescription, description } = useSeoTexts('Strona główna');

    const setCanonicalLink = () => {
        let relCanonical = '';
        if (typeof window !== 'undefined') {
            relCanonical = `${window.location.origin}${window.location.pathname}`;
        }
        return relCanonical;
    };

    return (
        <main className="index">
            <Helmet>
                <meta name="facebook-domain-verification" content="z9n3fzbpff8pidkow9o9cm4pf6kwiq" />
            </Helmet>
            <SEO title={ metaTitle } description={ metaDescription } canonicalLink={ setCanonicalLink() } />
            <Navigation />
            <Slider />
            <Breadcrumbs breadcrumbs={ breadcrumbs } />
            <CompanyView />
            <Bestsellers />
            <Offer />
            <BrandsView />
            <LeadTimes />
            <Showrooms />
            <Articles />
            <Implementations />
            <SeoDecription seoDescription={ description } />
            <Footer />
            <MessengerCustomerChat pageId={ MESSENGER_PAGE_ID } appId={ MESSENGER_APP_ID } />
        </main>
    );
};

export default Index;
