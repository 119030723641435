import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { ArticlesCarousel } from './articles-carousel';
import { paths } from 'utilities/routes';

export const ArticlesView = ({ articles, types }) => {
    const sortedArticlesByDate = useMemo(
        () => articles.sort((a, b) => {
            const dateOne = a.created_at.split('.');
            const dateTwo = b.created_at.split('.');
            return dateTwo[2] - dateOne[2] || dateTwo[1] - dateOne[1] || dateTwo[0] - dateOne[0];
        }),
        []
    );

    return (
        <ArticlesCarousel
            articles={ sortedArticlesByDate }
            types={ types }
            title="Wszystko o drzwiach"
            buttonText="Wszystkie artykuły"
            buttonLink={ paths.wszystkoODrzwiach }
        />
    );
};

ArticlesView.propTypes = {
    articles: PropTypes.instanceOf(Array).isRequired,
    types: PropTypes.instanceOf(Array).isRequired
};
