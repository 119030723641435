import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { SliderView } from './view';

export const Slider = () => {
    const data = useStaticQuery(graphql`
        query GetIndexSliderData {
            allSlides(filter: {
                id: {
                    ne: "dummy"
                }
            }) {
                nodes {
                    data {
                        name {
                            pl
                        }
                        banner_title {
                            pl
                        }
                        button_text {
                            pl
                        }
                        image
                        banner_main_color
                        target_link
                        background_position_x
                        position
                    }
                }
            }
        }
    `);

    return (<SliderView slides={ data.allSlides.nodes[0].data } />);
};