import React from 'react';

import { ButtonView } from 'utilities/components/button/component/view';
import { Img } from 'utilities/components/img/component';
import { paths } from 'utilities/routes';

import chisel from 'assets/images/index/chisel.svg';

import './view.scss';

export const CompanyView = () => (
    <section className="company">
        <h1 className="company__header headline">Barański drzwi wewnętrzne i zewnętrzne</h1>
        <div className="company__body">
            <div className="company__container">
                <h3 className="company__headline">Dlaczego Barański</h3>
                <p className="company__description">Już od ponad 30 lat w głębi Warmii i Mazur tworzymy drzwi klasy premium. Projektujemy je z najwyższą dbałością o wygląd i jakość. Wykorzystujemy prawdziwe drewno, ale chętnie sięgamy także po inne materiały. Znamy się na rzeczy.</p>
                <ButtonView text="Poznaj nas bliżej" link={ paths.jakosc } />
            </div>
            <Img className="company__icon" src={ chisel } alt="dłuto" />
        </div>
    </section>
);