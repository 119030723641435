import React from 'react';
import PropTypes from 'prop-types';

import { HeadlineView } from 'utilities/components/headline/component/view';
import { Img } from 'utilities/components/img/component';

import line from 'assets/images/index/line.svg';

import './view.scss';

export const LeadTimesView = ({ deadlines }) => (
    <section className="lead-times">
        <HeadlineView text="Terminy realizacji" />
        <div className="lead-times__container">
            {
                deadlines?.map((deadline, i) => i < 3 && (
                    <>
                        <div className="lead-times__time">
                            {
                                (() => {
                                    const [number, text] = deadline?.time?.split(' ');
                                    return (
                                        <>
                                            <p className="lead-times__time lead-times__time--large">{ number }</p>
                                            <p>{ text }</p>
                                        </>
                                    );
                                })()
                            }
                        </div>
                        <ul className="lead-times__list">
                            {
                                deadline?.bullets && JSON.parse(deadline?.bullets)?.map((bullet) => (<li className="lead-times__item">{ bullet?.name }</li>))
                            }
                        </ul>
                        {
                            i !== deadlines?.length - 1
                            && <Img className="lead-times__line" src={ line } alt="linia" />
                        }
                    </>
                )
                )
            }
        </div>
    </section>
);

LeadTimesView.propTypes = {
    deadlines: PropTypes.oneOfType([PropTypes.object]).isRequired
};